











































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Particles from "@/components/Particles.vue";
import Notifier from "@/helpers/notifier";
import { namespace } from "vuex-class";
import FinancialInstitution from "@/models/financialInstitution";
import agent from "@/helpers/agent";
const app = namespace("app");
@Component({ components: { Particles } })
export default class CreateUserView extends Vue {
  @app.Action
  login: any;

  roleName = "";
  submitting = false;

  onSubmit() {
    // implement register
    this.submitting = true;
    agent.Roles.addRole(this.roleName)
      .then(() => {
        Notifier.showPositive(`You have succesfully added the roles`);
        this.$router.push(`/update-roles?id=${this.roleName}`);
      })
      .catch(() => Notifier.showError("Error occured while adding role"))
      .finally(() => (this.submitting = false));
  }
  onReset() {
    this.roleName = "";
  }
}
